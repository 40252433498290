@import url('https://fonts.googleapis.com/css2?family=Signika&display=swap');


.plans{
    position: relative;
    top: 15vh;
}

.choose{
    display: flex;
    justify-content: center;
    font-family: 'Narnoor', serif;
    font-size: 3rem;
}

.art{
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.best, .designed, .for{
    background: linear-gradient(to right, #4e7976 0%, #ac4f56 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Caveat', cursive;
    font-size: 2.7rem;
}

.for{
    font-size: 3.5rem;
}


.planImage{
    height: 220px;
}

.allPlans{
    margin-top: 7vh;
    display: flex;
    justify-content: space-evenly;
}


.planDesc{
    height: auto;
    width: 100vw;
    background-color: rgb(238, 238, 238);
    margin-top: 40px;
    padding-bottom: 20px;
}

.planName{
    font-size: 2.7rem;
    margin-left: 40px;
    font-family: 'Narnoor', serif;
}

.plansBottom{
    margin-top: 2vh;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
}

.plan1, .plan2, .plan3, .plan4, .plan5, .plan6, .plan4Wedding, .plan4Diamond{
    background-color: #fff;
    border-radius: 16px;
    margin: 10px;
}

.plan1, .plan4, .plan5, .plan6, .plan4Wedding, .plan4Diamond{
    height: 250px;
    width: 450px;
}


.plan2, .plan3{
    height: 270px;
    width: 330px;
}

.plan3{
    position: relative;
    bottom: 12px;
}

.plan4, .plan4Wedding, .plan4Diamond{
    position: relative;
    top: 12px; 
}

.planImage1, .planImage{
    margin-left: 10px;
    margin-top: 10px;
    height: 110px;
    width: 110px;
    border-radius: 10px;
}

.planImage2{
    margin-left: 10px;
    margin-top: 10px;
    height: 110px;
    width: 110px;
    border-radius: 10px;
}

.planImage3{
    margin-left: 10px;
    margin-top: 10px;
    height: 110px;
    width: 110px;
    border-radius: 10px;
}

.planImage4{
    height: 90px;
    width: 150px;
}


.animatedHeading{
    position: relative;
    bottom: 20px;
    font-family: 'Narnoor', serif;
    font-size: 2.8rem;
    display: flex;
    justify-content: center;
    background: linear-gradient(to right, #30804b 0%, #d5dc10 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.animatedText{
    font-family: 'Narnoor', serif;
    margin: 10px;
    position: relative;
    bottom: 15px;
    font-size: 1.2rem;
}

.seven{
    position: relative;
    font-family: 'Narnoor', serif;
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    background: linear-gradient(to right, #8739c7 0%, #26cf75 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}


.photographerTeam{
    width: 330px;
    height: 200px;
    border-radius: 16px;
    margin-top: 8px;
}

.plan4, .plan4Wedding, .plan4Diamond{
    background-image: url('../../Assets/image51.jpg');
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}


.plan4 span, .plan4Wedding span, .plan4Diamond span{
    position: relative;
    bottom: 20px;
    margin-bottom: 0px;
    padding-right: 10px;
    font-size: 2rem;
    font-family: 'Narnoor', serif;
    background: linear-gradient(to right, #ffffff 0%, #c9c9c9 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.plan4Wedding span{
    background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.plan4Wedding{
    background-image: url('../../Assets/weddingSong1.jpg');
}

.plan4Diamond{
    background-image: url('../../Assets/weddingSong2.jpeg');
}

.plan5{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.videoQuality, .videoRecording, .videoQualityPlus{
    font-size: 2rem;
    position: relative;
    bottom: 8px;
    font-family: 'Narnoor', serif;
    background: linear-gradient(to right, #981ecd 0%, #34ccd7 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
    display: flex;
    justify-content: center;
}

.videoQualityPlus{
    background: linear-gradient(to right, #1cccdc 0%, #7034d7 100%);
    -webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
}


.two50{
    background: linear-gradient(to right, #cd901e 0%, #34ccd7 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
}

.sony{
    background: linear-gradient(to right, #1f64a4 0%, #34d75d 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
}

.k4{
    width: 300px;
    border-radius: 16px;
}

.videoQuality{
    background: linear-gradient(to right, #7a1a95 0%, #344cd7 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
}



@media only screen and (max-width: 1000px) {
    .plansBottom{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .plan1, .plan2, .plan3, .plan4, .plan5, .plan6, .plan4Wedding, .plan4Diamond{
        height: 250px;
        width: 450px;
    }

    .plan3{
        bottom: 0;
    }

    .plan4, .plan4Wedding, .plan4Diamond{
        top: 0;
    }

    .plan2{
        height: 320px;
    }

    .seven{
        top: 10px;
    }

    .photographerTeam{
        width: 450px;
        height: 225px;
        margin-top: 34px;
    }

}




@media only screen and (max-width: 900px) {
    .plans{
        top: 9vh;
    }
}

@media only screen and (max-width: 800px) {
    .allPlans{
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 600px) {
    .allPlans{
        grid-template-columns: auto auto;
    }

}



@media only screen and (max-width: 500px) {
    .plan1, .plan2, .plan3, .plan4, .plan5, .plan6, .plan4Wedding, .plan4Diamond{
        height: 250px;
        width: 360px;
    }

    .plan2{
        height: 300px;
    }

    .photographerTeam{
        width: 360px;
        height: 204px;
        margin-top: 34px;
    }

    .animatedHeading{
        font-size: 2.5rem;
        bottom: 18px;
    }

    .animatedText{
        bottom: 20px;
    }

}

@media only screen and (max-width: 400px) {
    .plan1, .plan2, .plan3, .plan4, .plan5, .plan6, .plan4Wedding, .plan4Diamond{
        height: 220px;
        width: 320px;
    }

    .plan2{
        height: 300px;
    }

    .photographerTeam{
        width: 320px;
        height: 204px;
        margin-top: 34px;
    }

    .animatedHeading{
        position: relative;
        font-size: 2rem;
    }

    .animatedText{
        margin: 7px;
        position: relative;
        bottom: 25px;
    }



    .k4{
        width: 250px;
    }
}

