@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap');

.nav{
    position: fixed;
    z-index: 10;
    height: 12vh;
    width: 100vw;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 0.26px #000;
}

.onlyLogo{
    position: relative;
    top: 9px;
    right: 10px;
    height: 42px;
}

.name{
    position: relative;
    bottom: 1.5vh;
    font-family: 'Caveat', cursive;
    font-size: 2rem;
}

.cam{
    background: linear-gradient(to right, #24b06a 0%, #b1120c 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.menu span{
    position: relative;
    bottom: 0.5vh;
    margin: 3rem;
    font-family: 'Inconsolata', monospace;
    font-size: 1.1rem;
}

.rightMenu{
    display: none;
}

.link{
    text-decoration: none;
    color: #000;
}


@media only screen and (max-width: 900px) {

    .nav{
        flex-direction: row;
        justify-content: space-between;
        width: 100vw;
        height: 7vh;
    }

    .name{
        margin-left: 2vw;
        margin-top: 2vh;
    }

    .menuList{
        display: none;
    }

    .rightMenu{
        display: block;
        margin-right: 1vw;
    }

    .onlyLogo{
        height: 36px;
        right: 3px;
    }

}
