.choose{
    display: flex;
    justify-content: center;
    font-family: 'Narnoor', serif;
    font-size: 3rem;
}

.planImage{
    height: 220px;
    border-radius: 5px;
    box-shadow: 7px 5px 6px rgba(0, 0, 0, 0.5);
}

.plan{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.planHeading{
    margin-top: 2vh;
    font-size: 1.5rem;
}

.planDesc{
    font-family: 'Narnoor', serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.line{
    width: 100px;
    height: 0.1vh;
    background-color: rgb(0, 0, 0);
    margin-top: 3vh;
    margin-bottom: 5vh;
}

.feature{
    color: rgb(56, 56, 56);
    margin-bottom: 4vh;
}





















@media only screen and (max-width: 450px) {
    .planImage{
        height: 180px;
    }

}